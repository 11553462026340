import React from 'react'

import styled from 'styled-components'

import { Container, Section, SectionTitle, Angle } from './styles'

import { TITLES } from './banner'

const Hero = styled.h1`
    text-align: center;
    font-weight: 400;
    max-width: 650px;
    margin: auto;
    font-size: 2rem;
    color: black;
    margin-top: 3rem;
    letter-spacing: 1px;
    line-height: 135%;
    padding: 0 0.5rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 3rem;
    }
`

const Text = styled.p`
    max-width: 700px;
    margin: auto;
    color: ${props => props.theme.gray4};
    font-size: 1.2rem;
    line-height: 135%;
    font-weight: 300;
    text-align: center;
    margin-top: 1rem;
    padding: 0 0.5rem;
    margin-bottom: 2rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 1.5rem;
    }
`

const PotentialSection = styled(Section)`
    text-align: left;
    background-color: ${props => props.theme.grayGreen};
    padding-bottom: 2rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        padding-bottom: 2rem;
    }
`

const PotentialTitle = styled(SectionTitle)`
    text-align: left;
    max-width: 700px;
    margin: 2rem auto;
    font-size: 2rem;
    margin-top: 4rem;
`

const PotentialText = styled(Text)`
    text-align: left;
    padding: 0;
    font-size: 1rem;
`

const PotentialAngle = styled(Angle)`
    &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 10px;
        background-color: ${props => props.theme.backgroundColor};
    }
`

const HeroContainer = styled(Container)`
    position: relative;
    z-index: 2;
`
const HeroSection = styled.section`
    position: relative;
    z-index: 2;
`
const Titles = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 2rem;
`
const Title = styled.li`
    max-width: 700px;
    margin: auto;
    color: ${props => props.theme.gray4};
    font-size: 1rem;
    line-height: 145%;
    font-weight: 300;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 1.5rem;
    }
`
export default () => {
    return (
        <>
            <HeroSection>
                <HeroContainer>
                    <Hero>Our mission is to make the world a better place</Hero>
                    <Text>
                        Verifeco is a technology company that builds software
                        products for the world. Businesses of every size—from
                        new startups to public companies—use our software to
                        exchange and manage their business card online. Since
                        being in beta the idea have changed how many corporates
                        and professionals think, use and issue business cards.
                    </Text>
                </HeroContainer>
                <PotentialAngle />
            </HeroSection>
            <PotentialSection>
                <PotentialTitle>
                    The potential of verified business cards
                </PotentialTitle>
                <PotentialText>
                    Despite internet businesses growing faster than the rest of
                    the economy, still most of the business exchange their
                    physical business card only to get lost or waisted most of
                    the time. How many times have you lost a contact or forgot
                    his/her name or have been lost as a contact.
                </PotentialText>
                <PotentialText>
                    Removing the barriers to create online, easy to use,
                    ecofriendly and verified business cards comes with the
                    following list of benefits:
                </PotentialText>
                <Titles>
                    {TITLES.map(title => (
                        <Title>{title}</Title>
                    ))}
                </Titles>
            </PotentialSection>
        </>
    )
}
