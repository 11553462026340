import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'
import Company from '../components/company'

export default () => (
    <Layout>
        <SEO
            title="Company"
            description={`Verifeco is a technology company that builds software
                        products for the world. Businesses of every size—from
                        new startups to public companies—use our software to
                        exchange and manage their business card online. Since
                        being in beta the idea have changed how many corporates
                        and professionals think, use and issue business cards.`}
        />
        <Header mode="site-header" />
        <Company />
        <Footer fixedHeight />
    </Layout>
)
